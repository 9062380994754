import { createRouter, createWebHistory } from 'vue-router';
import multiguard from 'vue-router-multiguard';
import { withPlatformMiddleware } from '@/router/middleware/withPlatform.js';
import { withAuthMiddleware } from '@/router/middleware/withAuth.js';
import { withoutPlatformMiddleware } from '@/router/middleware/withoutPlatform.js';
import { withoutAuthMiddleware } from '@/router/middleware/withoutAuth.js';
import { authByTokenMiddleware } from '@/router/middleware/authByTokenMiddleware.js';
import { withRolePermissions } from './middleware/withRolePermissions';
import { ROUTE_NAMES } from './routeNames';

const routes = [
  {
    name: ROUTE_NAMES.R_ROOT,
    path: '/',
    redirect: {
      name: ROUTE_NAMES.R_ROOT_MAIN,
    },
    children: [
      {
        path: '/main',
        name: ROUTE_NAMES.R_ROOT_MAIN,
        beforeEnter: multiguard([withPlatformMiddleware]),
        component: () => import('@/views/PublicPlatformHome.vue'),
      },
      {
        path: '/public/schedule',
        name: 'PublicSchedule',
        beforeEnter: multiguard([withPlatformMiddleware]),
        component: () => import('@/views/PublicSchedule.vue'),
      },
      {
        name: ROUTE_NAMES.R_ROOT_ERROR_PLATFORM_NOT_FOUND,
        path: 'errors/404-platform',
        component: () => import('@/views/Errors/404Platform'),
      },

      {
        name: 'PublicMaterials',
        path: '/guest',
        beforeEnter: multiguard([withPlatformMiddleware]),
        component: () => import('@/views/PublicMaterialsController'),
      },

      {
        name: ROUTE_NAMES.R_ROOT_FIRST_LOGIN,
        path: '/firstlogin',
        component: () => import('@/views/FirstLogin'),
        beforeEnter: multiguard([withPlatformMiddleware, withAuthMiddleware]),
      },

      {
        name: ROUTE_NAMES.R_ROOT_RESET_PASSWORD,
        path: '/reset-password',
        component: () => import('@/views/ResetPassword.vue'),
        beforeEnter: multiguard([withPlatformMiddleware]),
      },
      {
        name: ROUTE_NAMES.R_ROOT_RESET_PASSWORD_REQUEST,
        path: '/reset-password/request',
        component: () => import('@/views/ResetPasswordRequest.vue'),
        props: (route) => ({
          token: route.query.t,
        }),
        beforeEnter: multiguard([withPlatformMiddleware]),
      },
      {
        name: ROUTE_NAMES.R_ROOT_CONTROLLERS_MAIN,
        path: '/controllers/main',
        component: () => import('@/views/Controllers/MainController.vue'),
      },
      {
        name: 'NewPlatform',
        path: '/new-platform',
        component: () => import('@/layouts/EmptyLayout.vue'),
        beforeEnter: multiguard([withoutPlatformMiddleware, withoutAuthMiddleware]),
        children: [
          {
            name: ROUTE_NAMES.R_ROOT_PLATFORM_NEW,
            path: '',
            component: () => import('@/views/NewPlatform.vue'),
          },
        ],
      },
      {
        name: ROUTE_NAMES.R_ROOT_LOGIN,
        path: 'login',
        component: () => import('@/views/Login'),
        beforeEnter: multiguard([withPlatformMiddleware, withoutAuthMiddleware]),
      },
      {
        name: ROUTE_NAMES.R_ROOT_LOGIN_BY_TOKEN,
        path: 'token',
        beforeEnter: multiguard([withPlatformMiddleware, authByTokenMiddleware]),
      },

      {
        name: ROUTE_NAMES.R_TEACHER,
        component: () => import('@/layouts/AppLayout.vue'),
        path: 'teacher',
        beforeEnter: multiguard([withPlatformMiddleware, withAuthMiddleware, withRolePermissions(['teacher'])]),
        children: [
          {
            name: ROUTE_NAMES.R_TEACHER_SCHEDULE,
            component: () => import('@/views/TeacherSchedule.vue'),
            path: 'schedule',
          },
        ],
      },
      {
        name: 'ManagerSchedulePrint',
        path: 'schedule/print/:scheduleId',
        component: () => import('@/views/ManagerSchedulePrint.vue'),
      },
      {
        name: ROUTE_NAMES.R_MANAGER,
        path: 'manager',
        component: () => import('@/layouts/AppLayout.vue'),
        redirect: { name: ROUTE_NAMES.R_MANAGER_MATERIALS },
        beforeEnter: multiguard([
          withPlatformMiddleware,
          withAuthMiddleware,
          withRolePermissions(['manager', 'teacher']),
        ]),
        children: [
          {
            name: ROUTE_NAMES.R_MANAGER_MATERIALS,
            path: 'materials',
            component: () => import('@/views/ManagerMaterials.vue'),
            beforeEnter: multiguard([withPlatformMiddleware, withAuthMiddleware]),
          },

          {
            name: ROUTE_NAMES.R_MANAGER_MATERIALS_VIEW,
            path: 'materials/view/:materialId',
            component: () => import('@/views/ManagerMaterialsView.vue'),
            props: true,
            beforeEnter: multiguard([withPlatformMiddleware, withAuthMiddleware]),
          },

          {
            name: ROUTE_NAMES.R_MANAGER_MATERIALS_UPSERT_TASK,
            path: 'materials/task/upsert/:materialId?',
            props: true,
            component: () => import('@/views/ManagerMaterialsTaskUpsert.vue'),
            beforeEnter: multiguard([withPlatformMiddleware, withAuthMiddleware]),
          },

          {
            name: ROUTE_NAMES.R_MANAGER_MATERIALS_UPSERT_TEST,
            path: 'materials/test/upsert/:materialId?',
            props: true,
            component: () => import('@/views/ManagerMaterialsTestUpsert.vue'),
            beforeEnter: multiguard([withPlatformMiddleware, withAuthMiddleware]),
          },

          {
            name: ROUTE_NAMES.R_MANAGER_MATERIALS_UPSERT_SCORM,
            path: 'materials/scorm/upsert/:materialId?',
            props: true,
            component: () => import('@/views/ManagerMaterialsScormUpsert.vue'),
            beforeEnter: multiguard([withPlatformMiddleware, withAuthMiddleware]),
          },
          {
            name: ROUTE_NAMES.R_MANAGER_MATERIALS_UPSERT_COURSE,
            path: 'materials/course/upsert/:materialId?',
            component: () => import('@/views/ManagerMaterialsCourseUpsert.vue'),
            props: true,
            beforeEnter: multiguard([withPlatformMiddleware, withAuthMiddleware]),
          },

          {
            name: ROUTE_NAMES.R_MANAGER_CHECKUP,
            path: 'checkup',
            component: () => import('@/views/ManagerCheckup.vue'),
            beforeEnter: multiguard([withPlatformMiddleware, withAuthMiddleware]),
          },

          {
            name: ROUTE_NAMES.R_MANAGER_SCHEDULE_ROOT,
            path: 'schedule',
            beforeEnter: multiguard([withPlatformMiddleware, withAuthMiddleware]),
            children: [
              {
                name: ROUTE_NAMES.R_MANAGER_SCHEDULE,
                component: () => import('@/views/ManagerSchedule.vue'),
                path: '',
              },
              {
                name: ROUTE_NAMES.R_MANAGER_SCHEDULE_UPSERT,
                component: () => import('@/views/ManagerScheduleUpsert.vue'),
                path: 'upsert/:scheduleId?',
              },
            ],
          },

          {
            name: ROUTE_NAMES.R_MANAGER_USERS,
            path: 'users',
            component: () => import('@/views/ManagerUsers.vue'),
            redirect: { name: ROUTE_NAMES.R_MANAGER_USERS_ACCOUNTS },
            children: [
              {
                name: ROUTE_NAMES.R_MANAGER_USERS_ACCOUNTS,
                path: 'accounts',
                component: () => import('@/views/ManagerUsersAccounts.vue'),
              },

              {
                name: ROUTE_NAMES.R_MANAGER_USERS_GROUPS,
                path: 'groups',
                component: () => import('@/views/ManagerUsersGroups.vue'),
              },
              {
                name: ROUTE_NAMES.R_MANAGER_USERS_ORGSTRUCTURE,
                path: 'orgstructure',
                component: () => import('@/views/ManagerUsersOrgstructure.vue'),
              },
            ],
          },

          {
            name: ROUTE_NAMES.R_MANAGER_ANALYTICS,
            path: 'analytics',
            component: () => import('@/views/ManagerAnalytics'),
            redirect: {
              name: ROUTE_NAMES.R_MANAGER_ANALYTICS_DASHBOARD,
            },
            children: [
              {
                name: ROUTE_NAMES.R_MANAGER_ANALYTICS_DASHBOARD,
                path: 'dashboard',
                component: () => import('@/views/ManagerAnalyticsDashboard.vue'),
              },

              {
                name: ROUTE_NAMES.R_MANAGER_ANALYTICS_JOURNAL,
                path: 'journal',
                component: () => import('@/views/ManagerAnalyticsJournal.vue'),
              },

              {
                name: ROUTE_NAMES.R_MANAGER_ANALYTICS_STUDENTS,
                path: 'students',
                component: () => import('@/views/ManagerAnalyticsStudents.vue'),
              },
            ],
          },

          {
            name: ROUTE_NAMES.R_MANAGER_ASSIGNMENTS,
            path: 'assignments',
            component: () => import('@/views/ManagerAssignments'),
          },

          {
            name: ROUTE_NAMES.R_MANAGER_ASSIGNMENTS_VIEW,
            path: 'assignments/view/:assignmentId',
            props: true,
            component: () => import('@/views/ManagerAssignmentsView'),
          },

          {
            name: ROUTE_NAMES.R_MANAGER_ASSIGNMENTS_UPSERT,
            path: 'assignments/upsert/:assignmentId?',
            props: true,
            component: () => import('@/views/ManagerAssignmentsUpsert'),
          },

          {
            name: ROUTE_NAMES.R_MANAGER_SETTINGS,
            path: 'settings',
            component: () => import('@/views/ManagerSettings'),
          },
        ],
      },

      {
        name: ROUTE_NAMES.R_STUDENT,
        path: 'student',
        component: () => import('@/layouts/AppLayout.vue'),
        beforeEnter: multiguard([
          withPlatformMiddleware,
          withAuthMiddleware,
          withRolePermissions(['student', 'teacher']),
        ]),
        redirect: { name: 'StudentAssignments' },
        children: [
          {
            name: ROUTE_NAMES.R_STUDENT_MATERIALS_ALL,
            path: 'home',
            component: () => import('@/views/StudentHome.vue'),
          },

          {
            name: ROUTE_NAMES.R_STUDENT_SCHEDULE,
            path: 'schedule',
            component: () => import('@/views/StudentSchedule.vue'),
          },

          {
            name: ROUTE_NAMES.R_STUDENT_RESULTS,
            path: 'results',
            component: () => import('@/views/StudentResults.vue'),
          },

          {
            name: ROUTE_NAMES.R_STUDENT_MATERIALS_PLAY,
            path: 'materials/:materialSessionId',
            props: true,
            component: () => import('@/views/StudentMaterialsView.vue'),
          },

          {
            name: ROUTE_NAMES.R_STUDENT_MATERIALS_COURSE_PLAY,
            path: 'materials/course/:courseMaterialUserSessionId/material/:materialUserSessionId',
            props: true,
            component: () => import('@/views/StudentMaterialsCoursePlay.vue'),
          },
        ],
      },

      {
        name: ROUTE_NAMES.R_APP_USER_PROFILE,
        path: '/user/:userId',
        component: () => import('@/layouts/AppLayout.vue'),
        redirect: {
          name: 'UserProfileChild',
        },

        beforeEnter: multiguard([withPlatformMiddleware, withAuthMiddleware]),
        children: [
          {
            name: 'UserProfileChild',
            path: '',
            props: true,
            component: () => import('@/views/UserProfile.vue'),
          },

          {
            name: ROUTE_NAMES.R_APP_USER_PROFILE_SETTINGS,
            path: 'settings',
            props: true,
            beforeEnter: multiguard([withPlatformMiddleware, withAuthMiddleware]),
            redirect: {
              name: 'UserProfileSettingsChild',
            },
            children: [
              {
                name: 'UserProfileSettingsChild',
                path: '',

                component: () => import('@/views/UserProfileSettings.vue'),
              },
            ],
          },
        ],
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
